<template>
  <div>

    <div class="layout">
      <div class="header">
        <div class="wrap">
          <router-link to="/" class="connect"><a @click="handleConnect">{{ connectAddr }}</a></router-link>
          <router-link to="/" class="fgh"><img @click="gh" src="../assets/images/gh.png" alt=""></router-link>
          <div class="naver" v-if="nav">
            <ul>
              <li>
                <router-link to="/">Tutorials</router-link>
              </li>
              <li>
                <router-link to="/">Features</router-link>
              </li>
              <li>
                <router-link to="/">NFT Marketplace</router-link>
              </li>
              <li>
                <router-link to="/">News</router-link>
              </li>
              <li @click="dotwitter">
                <router-link to="/">Twitter</router-link>
              </li>
              <li>
                <router-link to="/">More...</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="fhpage1">
        <img src="../assets/images/fpic1.png" alt="">
        <div class="wrap">
          <div class="ftitle ">
            <h1 class="cc">FOR DESTINY</h1>
            <h6>Let the new tech and ancient divination lead your way better</h6>
          </div>
        </div>
      </div>
      <div class="fmodelban">
        <img src="../assets/images/fpic6.png" alt="">
        <div class="text ">
          <h2>For Destiny</h2>
          <h6>Let the new tech and ancient divination<br/> lead your way better</h6>
        </div>
      </div>
      <div class="fhpage2">
        <div class="wrap">
          <video class="video1 " controls="video" poster='../assets/images/fpic2.png'>
            <!--            <source :src='video1Url' type='video/mp4'>-->
            <source src='../assets/images/video1.mp4' type='video/mp4'>

          </video>
          <div class="icoa"></div>
        </div>
      </div>
      <div class="fhpage3">
        <div class="wrap">
          <div class="ftlt " v-if="isVisible">
            <h2>PRESALE STARTS IN</h2>
          </div>
          <div class="list" v-if="isVisible">
            <ul ref="elementToAnimate">


              <li>
                <h2 class="counter">{{ days }}</h2>
                <p>DAYS</p>
              </li>
              <li class=" ">
                <h2 class="counter">{{ hours }}</h2>
                <p>HOURS</p>
              </li>
              <li class=" ">
                <h2 class="counter">{{ minutes }}</h2>
                <p>MUNUTES</p>
              </li>
              <li class=" ">
                <h2 class="counter">{{ seconds }}</h2>
                <p>SECONDS</p>
              </li>
            </ul>
          </div>
          <div class="total ">
            <div class="tl">Total Supply : <i>250,0000,0000</i></div>
            <div class="vos">
              <div class="num"><i :style="{ width: percentage + '%' }"></i></div>
              <h4>{{ percentage }} %</h4>
            </div>
            <div class="contract">
              <div class="lt">
                <router-link to="/"><i>Contract:</i><input type="text" ref="textToCopy"
                                                           value="0x3B1A2A8930158a836DdBDb4831AA4734A90ACDbE" readonly>
                </router-link>
              </div>
              <button @click="copyText" class="wj">
                <router-link to="/"><img src="../assets/images/fn2.png"
                                         alt=''></router-link>
              </button>
            </div>
          </div>
          <form>
            <div class="list1">
              <ul>
                <li class=" ">
                  <div class="t">From</div>
                  <div class="has">
                    <input type="number" class="text"
                           v-model.trim="baseTotalPrice"
                           @input="changeBuyAmount(1)"
                           min="0.05"
                    >
                    <div class="rt">
                      <div class="max" @click="doMax">Min</div>
                      <div class="hb">
                        <div class="ico"><img src="../assets/images/fn3.png" alt=""></div>
                        <h3>ETH</h3>
                      </div>
                    </div>
                  </div>
                </li>
                <li class=" ">
                  <div class="t">To</div>
                  <div class="has">
                    <input type="number" class="text"
                           v-model.trim="baseAmount"
                           min="1000000"
                           @input="changeBuyAmount(2)">
                    <div class="rt">
                      <div class="max"></div>
                      <div class="hb">
                        <div class="ico"><img src="../assets/images/fn4.png" alt=""></div>
                        <h3>CRL</h3>
                      </div>
                    </div>
                  </div>
                  <div class="del">Min: 1000000（ Min 0.05Eth + 10 crc ）</div>
                </li>
              </ul>
            </div>
            <router-link to="/" class="fmore"><i @click="doSell">Sacrifice Now</i></router-link>
          </form>
        </div>
      </div>
      <div class="fhpage4">
        <div class="wrap">
          <div class="ftlt ">
            <h2>Master NFT</h2>
          </div>
          <div class="fileswiper " :class="{ 'active': factive1 == 0 }">
            <div class="swiper-wrapper">
              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img class="video1 " controls="video" poster='../assets/images/fpic3.png'>
                  <source src='../assets/images/fpic8.gif'>
                  <img/>
                  <!--
                                    <img src="../assets/images/fpic8.gif" alt="">
                  -->
                  <!--                  <img :src="fpic8Url" alt="">-->
                  <img src="../assets/images/fpic8.gif" alt="">

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--                    <img :src="fpic8Url" alt="">-->

                  </div>
                  <h2>FGW-3202-TS015</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Cassandra</h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/01</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Federal</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”Land Owner”.Can manipulating the land. Used to be a famous Real Estate Developer.
                    Right now,
                    she love to destroyed federal promotion strongholds.Severely impacted the progress
                    of the people in accepting mechanical modifications.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--                  <img :src="fpic8Url" alt="">-->

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--                    <img :src="fpic8Url" alt="">-->

                  </div>
                  <h2>FGW-3202-TS017</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Aiden</h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/02</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Nature</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Wind Whisperer”. Spreading rumors, manipulating public opinion
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS018</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Penelope</h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/03</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Crazy Rabbit</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Charmer”. Controlling the emotions and awareness of the populace, engaging in
                    actions that influence order.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS004</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Ariana</h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/04</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Star</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Fire Demon”. Setting fires ,destroying all accessible federal human evolution
                    facilities.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS005</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Theodore</h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/05</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Crazy Rabbit</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Elf”.Plant manipulation, providing food supplies and plant defense systems for
                    the resistance organization.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS006</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Giselle</h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/06</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Crazy Rabbit</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Mirror Shadow”.Shape-shifting and replication, Leader of the resistance spy
                    organization, main body not yet found.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS007</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Caleb</h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/07</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Federal</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Poseidonf”.Use to be the the first Admiral of the Federation Navy,
                    later manipulated the seas, destroyed an entire Navy stronghold planet, and then
                    went rogue
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->
                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS008</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Leona </h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/08</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Federal</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”Daughter of the Stars”.Used to be a death row inmate,
                    was sent by the Federation to explore the power of a new star,
                    and eventually gained immense power after accidentally falling into the star.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS009</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Victorina </h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/09</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Crazy Rabbit</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Prophet”. Ability Unkonw.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->
                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS011</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Lysander </h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/10</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Crazy Rabbit</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Judge”. Leader of the Interrogators, possibly possessing psychic abilities;
                    everyone interrogated by him has defected from the Federation.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS013</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Seraphina </h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/11</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Unknow</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Death”. Ability Unkonw.First discovered her in the core of an unknown planet,
                    but those who approached her would inexplicably die.
                    Now imprisoned in the deepest level of The Federation prison but Crazy Rabbit try to
                    save her.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>4.3k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>9.5k</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div class="swiper-slide clearfix">
                <div class="lt">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic8.gif" alt="">
                    <!--
                                        <img :src="fpic8Url" alt="">
                    -->

                  </div>
                  <h2>FGW-3202-TS014</h2>
                  <dl>
                    <dd>
                      <h4>Name</h4>
                      <h6>Sebastian </h6>
                    </dd>
                    <dd>
                      <h4>D.O.B</h4>
                      <h6>01/12</h6>
                    </dd>
                    <dd>
                      <h4>Creator</h4>
                      <h6>Unknow</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    ”The Freeman”. She can appear anywhere. There is no clear evidence to suggest she
                    belongs to Crazy Rabbit. However, in places where she appears, the Federation always
                    loses something.
                  </div>
                  <div class="lis">
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn6.png" alt=""></div>
                      <h6>2.9k</h6>
                    </div>
                    <div class="l">
                      <div class="ic"><img src="../assets/images/fn7.png" alt=""></div>
                      <h6>11.5k</h6>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="prev_next">
              <div class='prev'></div>
              <div class="vos">
                <div class="l" @click="factive1 = 1"><img src="../assets/images/fn11.png" alt=""></div>
                <div class="r" @click="factive1 = 0"><img src="../assets/images/fn10.png" alt=""></div>
              </div>
              <div class='next'></div>
            </div>
          </div>
        </div>
      </div>
      <div class="fhpage5">
        <div class="wrap">
          <div class="ftlt ">
            <h2>Discover More Features</h2>
            <h6>We offering more interesting feature and divination games here</h6>
          </div>
          <div class="tltls ">
            <ul>
              <li :class="{ 'on': activeIndex === 0 }" @click="toggleItem(0)">
                <router-link to="/"><a @click="openWebsite">Mining
                  Pool</a>
                </router-link>
                <i></i></li>
              <li :class="{ 'on': activeIndex === 1 }" @click="toggleItem(1)">
                <router-link to="/">Star
                  Guide
                </router-link>
                <i></i></li>
              <li :class="{ 'on': activeIndex === 2 }" @click="toggleItem(2)">
                <router-link to="/">Drawing
                  Lots
                </router-link>
                <i></i></li>
              <li :class="{ 'on': activeIndex === 3 }" @click="toggleItem(3)">
                <router-link to="/">Coming
                  Soon....
                </router-link>
                <i></i></li>
            </ul>
          </div>
          <div class="list ">
            <ul>
              <li :class="{ 'on': activeIndex === 0 }">
                <div class="pic"><img src="../assets/images/fpic3.jpg" alt=""></div>
                <div class="text">
                  <h3 @click="openWebsite">Mining Pool:</h3>
                  <p>
                    One of our early Features. Click on "MiningPool" to go to the DAPP for a preview and
                    purchase. We sell different level of NFTs at many prices which offering <br>
                    several levels of computing power. The higher the computing power level, the more
                    CrazyRabbit (CRC) you generate every 24 hours.<br>
                    Gas Fee:0.00012 ETH/100 computing power/24Hrs.<br>
                    The CRC quantity in the pool is limited, and as it reduces. Users who participate
                    earlier will have more CRC holdings and higher profits.<br>
                    For specific instructions, please watch the YouTube video tutorial.
                  </p>
                </div>
              </li>
              <li :class="{ 'on': activeIndex === 1 }">
                <div class="pic"><img src="../assets/images/fpic7.png" alt=""></div>
                <div class="text">
                  <h3>Star Guide:</h3>
                  <p>
                    The Path of the Stars will manifest under the guidance of the Revealer
                  </p>
                </div>
              </li>
              <li :class="{ 'on': activeIndex === 2 }">
                <div class="pic">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="text">
                  <h3>Drawing Lots:</h3>
                  <p>
                    Coming Soon........
                  </p>
                </div>
              </li>
              <li :class="{ 'on': activeIndex === 3 }">
                <div class="pic">
                  <img src="../assets/images/fpic8.gif" alt="">
                  <!--
                                    <img :src="fpic8Url" alt="">
                  -->

                </div>
                <div class="text">
                  <h3></h3>
                  <p>
                    More Features are developing....
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="fmodelp5">
        <div class="ftlt ">
          <h2>Discover More <br/> Features</h2>
          <h6>We offering more interesting feature and<br/> divination games here</h6>
        </div>
        <div class="fpools ">
          <div class="fdels">
            <ul>
              <li :class="{ 'on': activeIndex === 0 }">
                <h2 @click="openWebsite">Mining Pool:</h2>
                <h6>
                  Mining Pool<br/>
                  One of our early Features. Click on "MiningPool" to go to the DAPP for a preview and
                  purchase. We sell different level of NFTs at many prices which offering <br>
                  several levels of computing power. The higher the computing power level, the more
                  CrazyRabbit (CRC) you generate every 24 hours.<br>
                  Gas Fee:0.00012 ETH/100 computing power/24Hrs.<br>
                  The CRC quantity in the pool is limited, and as it reduces. Users who participate
                  earlier will have more CRC holdings and higher profits.<br>
                  For specific instructions, please watch the YouTube video tutorial.
                </h6>
              </li>
              <li :class="{ 'on': activeIndex === 1 }">
                <h2>Star Guide:</h2>
                <h6>
                  Star Guide<br/>
                  The Path of the Stars will manifest under the guidance of the Revealer

                </h6>
              </li>
              <li :class="{ 'on': activeIndex === 2 }">
                <h2>Drawing Lots:</h2>
                <h6>
                  Drawing Lots<br/>
                  Coming Soon........

                </h6>
              </li>
              <li :class="{ 'on': activeIndex === 3 }">
                <!--								<h2>Coming Soon:</h2>-->
                <h6>
                  More Features are developing....
                </h6>
              </li>
            </ul>
          </div>
          <div class="list">
            <ul>
              <li :class="{ 'on': activeIndex === 0 }" @click="toggleItem(0)">
                <div class="lt">
                  <div class="ico"><img src="../assets/images/fn12.png" alt=""></div>
                  <h3 @click="openWebsite">Mining Pool</h3>
                </div>
                <div class="next"></div>
              </li>
              <li :class="{ 'on': activeIndex === 1 }" @click="toggleItem(1)">
                <div class="lt">
                  <div class="ico"><img src="../assets/images/fn13.png" alt=""></div>
                  <h3>Star guide</h3>
                </div>
                <div class="next"></div>
              </li>
              <li :class="{ 'on': activeIndex === 2 }" @click="toggleItem(2)">
                <div class="lt">
                  <div class="ico"><img src="../assets/images/fn14.png" alt=""></div>
                  <h3>Drawing lots</h3>
                </div>
                <div class="next"></div>
              </li>
              <li :class="{ 'on': activeIndex === 3 }" @click="toggleItem(3)">
                <div class="lt">
                  <div class="ico"><img src="../assets/images/fn15.png" alt=""></div>
                  <h3>Coming Soon....</h3>
                </div>
                <div class="next"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="fhpage6">
        <div class="wrap">
          <div class="ftlt ">
            <h2>Fortune Tellers</h2>
          </div>
          <div class="fileswiper2 " :class="{ 'active': factive2 == 0 }">
            <ul class='swiper-wrapper'>
              <li class='swiper-slide'>
                <div class="lt">
                  <img src="../assets/images/zbs1.gif" alt="">
                  <!--                  <img :src="zbs1Url" alt="">-->
                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/zbs1.gif" alt="">
                  </div>
                  <!--                  <div class="pic">
                                      <img :src="zbs1Url" alt="">
                                    </div>-->
                  <dl>
                    <dd>
                      <h4>Name : </h4>
                      <h6>Eliana</h6>
                    </dd>
                    <dd>
                      <h4>Level : </h4>
                      <h6>The Fool</h6>
                    </dd>
                    <dd>
                      <h4>Proficient in : </h4>
                      <h6>Astrology/Tarot Card Reading/Crystal Ball Divination</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    Eliana has served as the chief fortune teller in various divination studios and
                    spiritual centers, offering divination services to clients and guiding them in
                    making important life decisions.
                  </div>
                  <div class="has">
                    <router-link to="/" class="now">Reservaiton Now</router-link>
                    <router-link to="/" class="wb"><img src="../assets/images/fn9.png"
                                                        alt=""></router-link>
                  </div>
                </div>
              </li>
              <li class='swiper-slide'>
                <div class="lt">
                  <img src="../assets/images/zbs2.gif" alt="">
                  <!--                  <img :src="zbs2Url" alt="">-->
                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/zbs2.gif" alt="">
                    <!--                    <img :src="zbs2Url" alt="">-->
                  </div>
                  <dl>
                    <dd>
                      <h4>Name : </h4>
                      <h6>Seraphina</h6>
                    </dd>
                    <dd>
                      <h4>Level : </h4>
                      <h6>The Fool</h6>
                    </dd>
                    <dd>
                      <h4>Proficient in : </h4>
                      <h6>Astrology/Tarot Card Reading/Mental Healing</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    Master's Degree: Master of Applied Psychology <br/>
                    Certified psychotherapist, integrating divination skills with psychology
                    to provide comprehensive counseling and therapy to clients.
                  </div>
                  <div class="has">
                    <router-link to="/" class="now">Reservaiton Now</router-link>
                    <router-link to="/" class="wb"><img src="../assets/images/fn9.png"
                                                        alt=""></router-link>
                  </div>
                </div>
              </li>

              <li class='swiper-slide'>
                <div class="lt">
                  <img src="../assets/images/zbs3.gif" alt="">
                  <!--                  <img :src="zbs3Url" alt="">-->
                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/zbs3.gif" alt="">
                    <!--                    <img :src="zbs3Url" alt="">-->
                  </div>
                  <dl>
                    <dd>
                      <h4>Name : </h4>
                      <h6>Isabella</h6>
                    </dd>
                    <dd>
                      <h4>Level : </h4>
                      <h6>The Fool</h6>
                    </dd>
                    <dd>
                      <h4>Proficient in : </h4>
                      <h6>Astrology/Tarot Card Reading/Amulets and Energy Cleansing</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    With 20 years of divination experience, she has provided divination consulting
                    services
                    to numerous celebrities and dignitaries. Seraphina has spent many years specializing
                    in amulets and energy direction, dedicated to helping individuals purify their body,
                    mind, and spirit while attracting positive energy. She continues to provide the most
                    powerful support for spiritual growth and exploration.
                  </div>
                  <div class="has">
                    <router-link to="/" class="now">Reservaiton Now</router-link>
                    <router-link to="/" class="wb"><img src="../assets/images/fn9.png"
                                                        alt=""></router-link>
                  </div>
                </div>
              </li>
              <!--							<li class='swiper-slide'>
                <div class="lt">
                  <img src="../assets/images/fpic5.png" alt="">
                </div>
                <div class="rt">
                  <div class="pic">
                    <img src="../assets/images/fpic7.png" alt="">
                  </div>
                  <dl>
                    <dd>
                      <h4>Name : </h4>
                      <h6>Seraphina</h6>
                    </dd>
                    <dd>
                      <h4>Level : </h4>
                      <h6>The Fool</h6>
                    </dd>
                    <dd>
                      <h4>Proficient in : </h4>
                      <h6>Astrology/Tarot Card Reading/Amulets and Energy Cleansing</h6>
                    </dd>
                  </dl>
                  <div class="t">Description</div>
                  <div class="del">
                    With 20 years of divination experience, she has provided divination consulting services
                    to numerous celebrities and dignitaries. Seraphina has spent many years specializing
                    in amulets and energy direction, dedicated to helping individuals purify their body,
                     mind, and spirit while attracting positive energy. She continues to provide the most
                      powerful support for spiritual growth and exploration.
                  </div>
                  <div class="has" @click="doBuy">
                    <router-link to="/" class="now">Reservaiton Now</router-link>
                    <router-link to="/" class="wb"><img src="../assets/images/fn9.png" alt=""></router-link>
                  </div>
                </div>
              </li>-->
            </ul>
            <div class="prev_next">
              <div class='prev'></div>
              <div class="vos">
                <div class="l" @click="factive2 = 1"><img src="../assets/images/fn11.png" alt=""></div>
                <div class="r" @click="factive2 = 0"><img src="../assets/images/fn10.png" alt=""></div>
              </div>
              <div class='next'></div>
            </div>
          </div>
        </div>
      </div>
      <div class="fhpage7">
        <div class="wrap">
          <div class="ftlt ">
            <h2>Our Partners</h2>
          </div>
          <div class="list ">
            <ul>
              <li><img src="../assets/images/fh1.png" alt=""></li>
              <li><img src="../assets/images/fh2.png" alt=""></li>
              <li><img src="../assets/images/fh3.png" alt=""></li>
              <li><img src="../assets/images/fh4.png" alt=""></li>
              <li><img src="../assets/images/fh5.png" alt=""></li>
              <li><img src="../assets/images/fh6.png" alt=""></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="wrap clearfix">
          <div class="naver">
            <dl>
              <dt>Links</dt>
              <dd>
                <router-link to="/">Buy $Crazy Rabbit</router-link>
              </dd>
              <dd @click="doaaa()">
                <router-link to="/">White Paper</router-link>
              </dd>
            </dl>
            <dl>
              <dt>Socials</dt>
              <dd @click="dotwitter()">
                <router-link to="/">Twitter</router-link>
              </dd>
              <dd @click="dotele()">
                <router-link to="/">Telegram Group</router-link>
              </dd>
              <dd>
                <router-link to="/">Contact Us</router-link>
              </dd>
            </dl>
            <dl>
              <dt>Legals</dt>
              <dd>
                <router-link to="/">Terms & Conditions</router-link>
              </dd>
            </dl>
          </div>
          <router-link to="/" class="logo"><img src="../assets/images/fh7.png" alt=""></router-link>
        </div>
      </div>
      <div class="copcript">
        <router-link to="/">By accessing our services, you accept our Terms of Service and Privacy
          Policy.
        </router-link>
        <br/>
        <router-link to="/">©2023 Crazy Rabbit. All rights reserved.</router-link>
      </div>
    </div>
    <div class="fuzhi" v-if="wben">
      <h4>{{ tishi }}</h4>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper';
import {Navigation, Pagination, Scrollbar} from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import Clipboard from 'clipboard';
import {
  InitMetMask,
  getBalance,
  getNetId,
  balanceOf,
  balanceOfCrl,
  approve,
  sell
} from "@/api/getweb3";
import web3 from "web3";


import Vue from 'vue';
import VueAnimateNumber from 'vue-animate-number';

Vue.use(VueAnimateNumber);

export default {
  name: 'HelloWorld',
  data() {
    return {
      isDisNum: false,
      baseAmount: "1000000",
      baseTotalPrice: "0.05",
      onePrice: "0.00000005",
      percentage: "0",
      netId: "1",
      accountFinal: '',
      connectAddr: 'Connect Wallet',
      activeIndex: 0, //
      factive1: 1,
      factive2: 1,
      nav: false,
      tishi: '',
      wben: false,
      from: 0,
      to: 40,
      isVisible: true,
      //countdownDate: 1697511600000, //
      countdownDate: 1697424101000,
      days: 0,
      video1Url: "https://crazyclub-1302315235.cos.ap-hongkong.myqcloud.com/video1.mp4",
      video2Url: "https://crazyclub-1302315235.cos.ap-hongkong.myqcloud.com/video2.mp4",
      fpic8Url: "https://crazyclub-1302315235.cos.ap-hongkong.myqcloud.com/fpic8.gif",
      zbs1Url: "https://crazyclub-1302315235.cos.ap-hongkong.myqcloud.com/zbs1.gif",
      zbs2Url: "https://crazyclub-1302315235.cos.ap-hongkong.myqcloud.com/zbs2.gif",
      zbs3Url: "https://crazyclub-1302315235.cos.ap-hongkong.myqcloud.com/zbs3.gif",
      hours: 0,
      minutes: 0,
      seconds: 0,
      otcAddr: "0x586ABDB5Aa187218Fc1B12e4e5d283569AA9466c"
    }
  },
  created() {
    this.handleConnect();
    this.Onlist();
    this.doPercentage();
  },

  methods: {

    openWebsite() {
      window.open('https://www.crazynftsgames.com/', '_blank');
    },
    /************** doPercentage ****/
    doPercentage() {
      balanceOfCrl(this.otcAddr).then(res => {
        let amountNow = Number(res) / (1 * 10 ** 9) / (1 * 10 ** 9);
        let lsAm = Number(25000000000) - Number(amountNow);
        console.log("lsAm:{}"+lsAm)
        this.percentage = (Number(lsAm) / Number(25000000000)).toFixed(6) * 100;
      })
    },

    /************** Onlist ****/
    Onlist() {
      // 监听切换链事件
      window.ethereum.on('chainChanged', (chainId) => {
        this.netId = chainId.startsWith("0x") ? chainId.slice(2) : chainId;
        this.handleConnect()
      });

      window.ethereum.on('accountsChanged', (accounts) => {
        this.handleConnect()
      });
    },

    /************** doMax ****/
    doMax() {
      this.baseAmount = 1000000;
      this.baseTotalPrice = Number("0.05");
    },


    /************** changeBuyAmount ****/
    changeBuyAmount(type) {
      if (this.netId != 42161) {
        this.$message.warning("switch to arb chain first")
        return
      }
      if (this.accountFinal == "") {
        this.$message.warning("Looks like you need a Dapp browser to get started!")
        return
      }
      balanceOf(this.accountFinal).then(res => {
        if (type == 1) {
          //One account can hold up to 5000.
          //console.log("type"+type)
          //console.log("this.baseTotalPrice"+this.baseTotalPrice)
          if (Number(this.baseTotalPrice) < 0.05) {
            this.$message.warning("Min 0.05")
            //this.baseTotalPrice = 0.05;
          }

          this.baseAmount = (Number(this.baseTotalPrice) / Number(this.onePrice)).toFixed(0);
        } else {
          if (this.baseAmount < 1000000) {
            this.$message.warning("Min 1000000")
            //this.baseAmount = 1000000;
          }
          /*          if (this.baseAmount < 500 || this.baseAmount < 0) {
                      this.$message.warning("min 500")
                      this.baseAmount = 500;
                    }*/
          this.baseTotalPrice = Number(this.baseAmount) * Number(this.onePrice);
        }
        let amountNow = Number(res) / (1 * 10 ** 9) / (1 * 10 ** 9);
        /*     if (amountNow < 10) {
               this.$message.warning("First, go to this website (https://app.uniswap.org/swap?outputCurrency=0xe4177c1400a8eee1799835dcde2489c6f0d5d616) to purchase 10 CRC tokens, which is necessary before being able to purchase CRL.")
               return;
             }*/

        if (amountNow < 10) {
          this.$alert(
              "First, go to this website (https://app.uniswap.org/swap?outputCurrency=0xe4177c1400a8eee1799835dcde2489c6f0d5d616) to purchase 10 CRC tokens, which is necessary before being able to purchase CRL.",
              "Tips",
              {
                confirmButtonText: "Go to Buy",
                callback: action => {
                  if (action === "confirm") {
                    window.open("https://app.uniswap.org/swap?outputCurrency=0xe4177c1400a8eee1799835dcde2489c6f0d5d616", "_blank");
                  }
                }
              }
          );
        }
      })
    },

    /************** sell ****/
    doSell() {
      getNetId().then(netId => {
        this.netId = netId
        if (this.netId != 42161) {
          this.$message.warning("switch to arb chain first")
          return
        }
        if (Number(this.baseTotalPrice) < 0.05 || Number(this.baseAmount) < 1000000) {
          this.$message.warning("min 0.05 eth")
          return;
        }

        balanceOfCrl(this.otcAddr).then(res => {
          let amountNow = Number(res) / (1 * 10 ** 9) / (1 * 10 ** 9);
          if (amountNow <= 1000) {
            this.$message.warning("Sold out")
            return;
          }
          if (amountNow <= this.baseAmount) {
            this.baseAmount = amountNow;
            this.baseTotalPrice = Number(this.baseAmount) * Number(this.onePrice);
          }
          balanceOf(this.accountFinal).then(res => {
            let amountNow = Number(res) / (1 * 10 ** 9) / (1 * 10 ** 9);
            if (amountNow < 10) {
              this.$message.warning("Clearly states that to purchase CRL, 10 CRC tokens must be held first")
              return;
            } else {
              if (this.baseTotalPrice == 0 || this.baseAmount == 0) {
                this.$message.warning("Wrong quantity")
                return;
              }


              var priceTo = (Number(this.baseTotalPrice)).toString();

              getBalance(this.accountFinal).then(reseth => {
                if (Number(reseth) < priceTo) {
                  this.$message.warning("Insufficient found (eth)")
                  return;
                } else {
                  approve(this.accountFinal, "10000000000000000000").then(res => {
                    if (res.substr(0, 2) == "0x") {
                      sell(this.accountFinal, priceTo).then(res => {
                        if (res.substr(0, 2) == "0x") {
                          this.$message.success("Success");
                          this.doPercentage();
                        }
                      }).catch(e => {
                        this.$message.warning("error");
                      })
                    }
                  }).catch(e => {
                    this.$message.warning("error");
                  })
                }
              })
            }
          })
        })
      })

    },
    //https://www.crazynftsgames.com/
    /**************connect****/
    handleConnect() {
      InitMetMask((res) => {
        this.connectAddr = "";
        this.accountFinal = web3.utils.toChecksumAddress(res);
        //console.log("accountFinal:" + this.accountFinal)
        var len = 3;
        var xing = '';
        for (var i = 0; i < len; i++) {
          xing += '*';
        }
        this.connectAddr = this.accountFinal.substring(0, 3) + xing + this.accountFinal.substring(this.accountFinal.length - 3)
        getNetId().then(netId => {
          this.netId = netId
          if (this.netId != 42161) {
            this.$message.warning("switch to arb chain first")
          }
        })
      });
    },

    doaaa() {

      window.location.href = "https://crazyrabbitclub.com/doc/";
      // window.open('https://crazyrabbitclub.com/doc/', '_blank')

    },
    dotwitter() {

      window.location.href = "https://twitter.com/CRabbit_Society";
      // window.open('https://crazyrabbitclub.com/doc/', '_blank')

    },

    dotele() {

      window.location.href = "https://t.me/CrazyRabbitEN";
      // window.open('https://crazyrabbitclub.com/doc/', '_blank')

    },


    doBuy() {
      //console.log("do buy")
      /*      this.tishi = 'comming soon';
            this.wben = true;*/
      /*  clearInterval(this.timer); //
        this.timer = setInterval(() => {
        this.wben = false;
        }, 2000);*/
    },
    updateCountdown() {
      const currentTime = new Date().getTime();
      const timeDifference = this.countdownDate - currentTime;

      if (timeDifference > 0) {
        this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      } else {
        //
        this.isVisible = false
        this.isDisNum = true
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
      }
    },


    toggleItem(index) {
      let that = this;
      that.activeIndex = that.activeIndex === index ? -1 : index;
    },
    gh() {
      let that = this;
      that.nav = !that.nav;
    },
    copyText() {
      let that = this;
      const textToCopy = this.$refs.textToCopy;
      const clipboard = new Clipboard('.wj', {
        text: () => textToCopy.value,
      });

      clipboard.on('success', (e) => {
        that.tishi = 'copy success';
        that.wben = true;
        clearInterval(this.timer); // 清除旧的定时器
        this.timer = setInterval(() => {
          that.wben = false;
        }, 1000);
        e.clearSelection();
        clipboard.destroy(); // 销毁实例以释放资源
      });

      clipboard.on('error', (e) => {
        that.tishi = 'fail';
        that.wben = true;
        clearInterval(this.timer); // 清除旧的定时器
        this.timer = setInterval(() => {
          that.wben = false;
        }, 1000);

        clipboard.destroy(); // 销毁实例以释放资源
      });

      //
      clipboard.onClick(event);
    },


  },
  mounted() {
    this.handleConnect();
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000); // 每秒更新一次倒计时
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.isVisible = true;
        observer.disconnect();
      }
    });
    const element = this.$refs.elementToAnimate;
    observer.observe(element);
    new Swiper('.fileswiper', {
      modules: [Navigation, Pagination, Scrollbar],
      navigation: {
        nextEl: '.fileswiper .next',
        prevEl: '.fileswiper .prev',
      },
      slidesPerView: 1,
      spaceBetween: 30, //
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      observer: true,
      observeParents: true,
    });

    new Swiper('.fileswiper2', {
      modules: [Navigation, Pagination, Scrollbar],
      navigation: {
        nextEl: '.fileswiper2 .next',
        prevEl: '.fileswiper2 .prev',
      },
      slidesPerView: 1,
      spaceBetween: 30, //
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      observer: true,
      observeParents: true,
    });
  },
}


</script>
<style>
@font-face {
  font-family: 'EliantoRegular';
  src: url(../assets/fonts/Elianto-Regular.ttf);
}


div {
  font-family: EliantoRegular;
}
</style>


