import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Web3 from 'web3'
import axios from 'axios';



Vue.config.productionTip = false
import './assets/css/slicy.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.Web3 = Web3
Vue.prototype.$http = axios
Vue.prototype.$ajax = axios
Vue.use(ElementUI);

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
