<template>
	<div>
		<div class="layout flayout1">
			<div class="flogin">
				<img src="../assets/images/fh9.png" alt="">
				<div class="hass">
					<div class="ftlt">
						<h2>Permission Access</h2>
						<h4>Please make up your new I.D.</h4>
					</div>
					<form>
						<ul>
							<li><input type="text" class="text" id="username" placeholder="Name"></li>
							<li><input type="text" class="text" id="username" placeholder="DOB : mm/dd/yyyy"></li>
							<li>
								<select name="" class="lsele">
									<option value="Enter A Number (From 1-12)">Enter A Number (From 1-12)</option>
									<option value="Enter A Number (From 1-12)2">Enter A Number (From 1-12)2</option>
								</select>
							</li>
						</ul>
						<a href="#" class="fmore"><i>Complete</i></a>
					</form>
				</div>
			</div>
			
			
		</div>
	</div>
	
</template>
<script>
	import vueDanmaku from 'vue-danmaku'
	
	export default {
		name: "barrage",
		components: {
		    vueDanmaku
		  },
		name: 'HelloWorld',
		data() {
			return {
				
			}
		},

		methods: {
		   
		   
		}

	}
</script>
		